<template>
  <div class="layout">
    <van-pull-refresh
      v-model="isLoading"
      @refresh="onRefresh"
      success-text="刷新成功"
    >
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        :error.sync="error"
        error-text="请求失败，点击重新加载"
        @load="onLoad"
        :offset="10"
      >
        <div class="list" v-for="(item, index) in storeList" :key="index">
          <div class="list_top">
            <div class="store_logo">
              <img :src="item.storeLogo" alt="" />
              <span>{{ item.storeName }}</span>
            </div>
            <span class="to_store" @click="toStore(item)">进店</span>
          </div>
          <div :class="item.storeCommodityItems.length < 1 ? '' : 'item'">
            <div
              class="item_sub"
              v-for="(list, index1) in item.storeCommodityItems"
              :key="index1"
              @click="toDetail(item.enterpriseId, list)"
            >
              <img :src="list.filePath" alt="" />
              <span class="name">{{ list.commodityName }}</span>
              <p class="price" v-if="list.goodsStatus === 1">
                <span>￥</span
                >{{ list.storeTastingActivityInfoEntity.price.toFixed(2) }}
              </p>
              <p class="price" v-else-if="list.goodsStatus === 2">
                <span>￥</span
                >{{
                  list.querySecKillCommodityListRsp.retailSeckillPrice.toFixed(
                    2
                  )
                }}
              </p>
              <p class="price" v-else-if="userType === 'PURCHASE'">
                <span>￥</span>{{ list | tradePrice }}
              </p>
              <p class="price" v-else>
                <span>￥</span>{{ list | retailPrice }}
              </p>
            </div>
            <!-- <p v-show="item.storeCommodityItems.length < 1">无</p> -->
          </div>
        </div>
        <p class="pull_up" v-if="!(error || finished || loading)">
          上拉 查看更多推荐商品 <svg-icon icon-class="home_more_icon" />
        </p>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { preferential } from "@/utils/validate";
import { mapState } from "vuex";
export default {
  name: "mine",
  computed: {
    ...mapState("user", ["tzInfo", "isLogin", "userInfo", "userType"]),
  },
  data() {
    return {
      storeList: [],
      isLoading: false,
      loading: false,
      finished: false,
      error: false,
      pageNum: 1,
      total: null,
      scrollTop: 0,
    };
  },
  filters: {
    tradePrice(value) {
      return preferential(value, "tradePrice").price;
    },
    retailPrice(value) {
      return preferential(value, "retailPrice").price;
    },
  },

  mounted() {},
  created() {},
  activated() {
    window.addEventListener("scroll", this.windowScroll, true);
    document.getElementsByClassName("layout")[0].scrollTop = this.scrollTop;
  },
  deactivated() {
    window.removeEventListener("scroll", this.windowScroll, true);
  },
  beforeRouteEnter(to, from, next) {
    if (from.name != "store-home-page" && from.name != "goods-details") {
      next((vm) => {
        vm.storeList = [];
        vm.loading = true;
        vm.pageNum = 1;
        vm.total = null;
        vm.finished = false;
        vm.error = false;
        vm.scrollTop = 0;
        vm.getStore();
      });
    }

    next();
  },
  methods: {
    windowScroll() {
      this.scrollTop = document.getElementsByClassName("layout")[0].scrollTop;
    },
    toDetail(id, data) {
      this.$router.push({
        path: "goodsDetails",
        query: {
          barcode: data.barcode,
          enterpriseId: id,
        },
      });
    },
    async getStore() {
      await this.$API.homePage
        .enterprise({
          object: {
            barcode: "",
            commodityCode: "",
            enterpriseId: "",
            like: "",
            memberId: this.isLogin ? this.userInfo.id : undefined,
            userId: this.tzInfo ? this.tzInfo.id : "",
          },
          pageNum: this.pageNum,
          pageSize: 10,
        })
        .then((res) => {
          if (res.data.code != "SUCCESS") throw Error(res.data.msg);
          this.total = res.data.data.total;
          let data = res.data.data.enterpriseHomePageItems;
          data.forEach((item) => {
            item.storeCommodityItems.forEach((item1) => {
              item1.filePath = item1.filePath.split(",")[0].endsWith(".mp4")
                ? item1.filePath.split(",")[1]
                : item1.filePath.split(",")[0];

              if (
                res.data.data.newUser &&
                item1.storeTastingActivityInfoEntity &&
                item1.querySecKillCommodityListRsp
              ) {
                if (
                  item1.storeTastingActivityInfoEntity.price <=
                  item1.querySecKillCommodityListRsp.retailSeckillPrice
                ) {
                  item1.goodsStatus = 1;
                } else {
                  item1.goodsStatus = 2;
                }
              } else if (
                res.data.data.newUser &&
                item1.storeTastingActivityInfoEntity
              ) {
                item1.goodsStatus = 1;
              } else if (item1.querySecKillCommodityListRsp) {
                item1.goodsStatus = 2;
              } else {
                item1.goodsStatus = 0;
              }
            });
          });
          this.storeList = this.storeList.concat(data);
          if (this.pageNum > this.total / 10) {
            this.finished = true;
          }
          this.pageNum++;
          this.loading = false;
        })
        .catch((err) => {
          console.error(err.message);
          this.error = true;
          this.loading = false;
        });
    },
    async onLoad() {
      this.getStore();
    },
    onRefresh() {
      setTimeout(() => {
        // Toast("刷新成功");
        this.storeList = [];
        this.pageNum = 1;
        this.finished = false;
        this.isLoading = false;
        this.getStore();
      }, 1000);
    },
    toStore(data) {
      this.$router.push({
        path: "store",
        query: {
          storeId: data.storeId,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.layout {
  position: absolute;
  top: 46px;
  bottom: 0;
  left: 0;
  right: 0;
  //   max-width: 380px;
  // margin: 0 auto;
  overflow-y: scroll;
  // height: 100vh;
  background: #f5f5f5;
  padding-top: 12px;
}
.list {
  background: #fff;
  padding: 12px;
  margin: 12px;
  margin-top: 0;
}
.list_top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .to_store {
    height: 20px;
    line-height: 20px;
    color: #fff;
    background: #009a4d;
    padding: 0 10px;
    border-radius: 20px;
  }
}
.store_logo {
  display: flex;
  align-items: center;
  img {
    width: 20px;
    height: 20px;
    margin-right: 12px;
  }
}
.item {
  display: flex;
  overflow: auto;
  margin-top: 12px;
  .item_sub {
    padding: 0 6px;
    text-align: center;
  }
  .item_sub:first-child {
    padding-left: 0;
  }
  .item_sub:last-child {
    padding-right: 0;
  }
  img {
    width: 80px;
    height: 80px;
    border-radius: 6px;
  }
  p {
    margin-top: 4px;
    color: #fb4e10;
  }
  span.name {
    display: block;
    width: 80px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #333;
  }
}
.item::-webkit-scrollbar {
  display: none;
}
</style>
